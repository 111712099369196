import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { adminRoot, authRoot, setCurrentUserFunc } from 'constants/defaultValues';
import { log, setCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  LOGOUT_USER,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
} from './actions';
// import axios from 'axios';
import { loginWithEmailAndPasswordApi } from 'services/auth.services';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

// const loginWithEmailPasswordAsync = async (email, password) =>{
//   // eslint-disable-next-line no-return-await
//   log(email,password)
//   if(email == 'ravi@insurancesamadhan.com') {
//     try {
//       const res = await axios({
//         method:"POST",
//         url:servicePath + apiEndpoints.login,
//         data:{
//           email,
//           password
//         }
//       });
//       return res.data;
//     } catch (error) {
//       log(error)
//       return error;
//     }
//   } else {
//     try {
//       const res = await axios({
//         method: "POST",
//         url: agentApiPath + '/login',
//         data:{
//           email,
//           password
//         }
//       });
//       return res.data;
//     } catch (error) {
//       log(error)
//       return error;
//     }
//   }

  
// };

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailAndPasswordApi,{ email, password});
    if (loginUser.success) {
      const isAdmin = loginUser.data?.userType === "admin";
      yield call(loginUserSuccess, {isAdmin,...loginUser})
      // log({...loginUser,isAdmin}, adminRoot, getCurrentUser())
      setCurrentUserFunc()
      history.push(adminRoot);
      // window.location.reload();
      yield put({type:"LEAD_COUNT_BY_STATUS", state:{user_id:loginUser?.data?.user_id}})
    } else {
      yield put(loginUserError(loginUser.msg));
    }
  } catch (error) {
    const msg = error.response?.data?.message || error.message;
    yield put(loginUserError(msg));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  log(authRoot);
  setCurrentUser();
  setCurrentUserFunc()
  history.push(authRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync, history);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
  ]);
}
